import { fetch } from '..';
import { QueryMethod } from '../utils';
import { AuthForgotPasswordDto, RefreshTokenDto, SetupNewPasswordDto } from './dto';

export async function confirmEmail(id: string, code: string) {
  const response = await fetch('/api/platform/v1/auth/approve-pending-email', QueryMethod.POST, {
    code,
    id
  });

  return response;
}

export async function refreshTokenRequest(): Promise<RefreshTokenDto> {
  const response = await fetch<RefreshTokenDto>(
    '/api/platform/v1/auth/refresh-access-token',
    QueryMethod.POST,
  );

  return response;
}

export function forgotPassword(email: string) {
  return fetch('/api/platform/v1/auth/forgot-password', QueryMethod.POST, { email });
}

