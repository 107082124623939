/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useEffect, useReducer, useMemo, useState, ReactElement } from 'react';
import { decodeToken } from 'react-jwt';

import axios from 'src/utils/axios';
import { RoleEnum, UserDto } from '../../requests/auth/dto';
import { basePortalURL } from '../../routes';
import { isValidToken, setRefreshToken, setSession } from './Jwt';
import { refreshTokenRequest } from '../../requests/auth';
import { omit } from 'lodash';

const API_URL = process.env.REACT_APP_API_URL || 'https://product.velomatch.io';

export interface InitialStateType {
  isAuthenticated: boolean;
  isInitialized?: boolean;
  user?: UserDto | null;
}

export interface AuthContextType extends InitialStateType {
  [k: string]: unknown;
  platform: 'JWT';
  method: 'jwt';
  signup: (email: string, password: string, firstName: string, lastName: string) => Promise<void>;
  signin: (email: string, password: string, pageToLogin: string) => Promise<void>;
  refreshToken: () => Promise<void>;
  logout: () => Promise<void>;
  resetPassword: (
    id: string,
    code: string,
    password: string,
    confirmPassword: string,
  ) => Promise<void>;
  newPasswordSetup: (
    id: string,
    code: string,
    password: string,
    confirmPassword: string,
  ) => Promise<void>;
  setUpDeityMode: (tenantId: string) => Promise<void>;
  isEmployer: boolean;
  isRetailer: boolean;
  isAdmin: boolean;
  isSuperAdmin: boolean;
  isNotAdmin: boolean;
  isEmployee: boolean;
}

const initialState: InitialStateType = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

export interface UserFromAccessToken {
  email?: string;
  firstName?: string;
  lastName?: string;
  tenants?: TenantFromAccessToken[];
}

export interface TenantFromAccessToken {
  tenantId: string;
  roles: RoleFromAccessToken[];
}

export interface RoleFromAccessToken {
  roleId: string;
  roleType: string;
}

const getUserFromAccessToken = (accessToken: string): UserDto => {
  const userFromToken: UserFromAccessToken = decodeToken<UserFromAccessToken>(accessToken) || {};
  const user = omit(userFromToken) as unknown as UserDto;
  user.roles = [userFromToken.tenants![0].roles[0].roleType as RoleEnum];
  user.tenantId = userFromToken.tenants![0].tenantId;

  return user;
};

const handlers: any = {
  INITIALIZE: (state: InitialStateType, action: any) => {
    const { isAuthenticated, user } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  },
  LOGIN: (state: InitialStateType, action: any) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
  LOGOUT: (state: InitialStateType) => ({
    ...state,
    isAuthenticated: false,
    user: null,
  }),
  REGISTER: (state: InitialStateType, action: any) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
};

const reducer = (state: InitialStateType, action: any) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

const AuthContext = createContext<AuthContextType>({
  ...initialState,
  method: 'jwt',
  platform: 'JWT',
  signup: () => Promise.resolve(),
  signin: () => Promise.resolve(),
  refreshToken: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  resetPassword: () => Promise.resolve(),
  newPasswordSetup: () => Promise.resolve(),
  setUpDeityMode: (tenantId: string) => Promise.resolve(),
  isEmployer: false,
  isNotAdmin: false,
  isAdmin: false,
  isRetailer: false,
  isSuperAdmin: false,
  isEmployee: false,
});

function AuthProvider({ children }: { children: ReactElement }) {
  const [initialization, setInitialization] = useState(true);

  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      try {
        const token = window.localStorage.getItem('token');
        const refreshJwtToken = window.localStorage.getItem('refresh-token');

        if (token && refreshJwtToken && isValidToken(refreshJwtToken)) {
          const user = getUserFromAccessToken(token);
          if (token && !isValidToken(token)) {
            setRefreshToken(refreshJwtToken);
            await refreshToken();
          } else {
            setSession(token);
            setRefreshToken(refreshJwtToken);

            if (!user) {
              throw new Error('Invalid token.');
            }

            dispatch({
              type: 'INITIALIZE',
              payload: {
                isAuthenticated: true,
                user: { ...(state.user ?? {}), ...user },
              },
            });
          }
        } else {
          await logout();
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      } finally {
        setInitialization(false);
      }
    };

    initialize();
  }, []);

  const signin = async (email: string, password: string, pageToLogin: string) => {
    const response = await axios.post(API_URL + '/api/platform/v1/auth/signin', {
      email,
      password,
      pageToLogin,
    });

    const { data } = response.data;
    const { accessToken, refreshToken } = data;
    setSession(accessToken);
    setRefreshToken(refreshToken);

    const user = getUserFromAccessToken(accessToken);

    dispatch({
      type: 'LOGIN',
      payload: {
        user,
      },
    });
  };

  const refreshToken = async () => {
    const response = await refreshTokenRequest();
    const { accessToken } = response;
    const user = getUserFromAccessToken(accessToken);

    setSession(accessToken);

    dispatch({
      type: 'LOGIN',
      payload: {
        user,
      },
    });
  };

  const signup = async (email: string, password: string, firstName: string, lastName: string) => {
    const response = await axios.post(API_URL + '/gogeta-api/v1/auth/admin/email/register', {
      email,
      password,
      firstName,
      lastName,
    });
    const { token, user } = response.data;

    window.localStorage.setItem('token', token);
    dispatch({
      type: 'REGISTER',
      payload: {
        user,
      },
    });
  };

  const resetPassword = async (
    id: string,
    code: string,
    password: string,
    confirmPassword: string,
  ) => {
    const response = await axios.post(API_URL + '/api/platform/v1/auth/approve-signup', {
      id: id,
      code: code,
      password: password,
      repeatedPassword: confirmPassword,
    });

    const { data } = response.data;
    const { accessToken, refreshToken } = data;
    setSession(accessToken);
    setRefreshToken(refreshToken);

    const user = getUserFromAccessToken(accessToken);

    dispatch({
      type: 'LOGIN',
      payload: {
        user,
      },
    });
  };

  const newPasswordSetup = async (
    id: string,
    code: string,
    password: string,
    confirmPassword: string,
  ) => {
    const response = await axios.post(API_URL + '/api/platform/v1/auth/new-password-setup', {
      id: id,
      code: code,
      password: password,
      repeatedPassword: confirmPassword,
    });

    const { data } = response.data;
    const { accessToken, refreshToken } = data;

    setSession(accessToken);
    setRefreshToken(refreshToken);

    const user = getUserFromAccessToken(accessToken);

    dispatch({
      type: 'LOGIN',
      payload: {
        user,
      },
    });
  };

  const logout = async () => {
    setSession(null);
    setRefreshToken(null);
    dispatch({ type: 'LOGOUT' });
  };

  const setUpDeityMode = async (tenantId: string) => {
    const response = await axios.post(API_URL + '/api/platform/v1/admin-auth/deity-mode', {
      tenantId,
    });

    const { token, refreshToken } = response.data;

    setSession(token);
    setRefreshToken(refreshToken);

    const user = getUserFromAccessToken(token);
    user.deityMode = true;

    dispatch({
      type: 'LOGIN',
      payload: {
        user,
      },
    });

    window.location.replace(`${window.location.origin}${basePortalURL}/invite-employees`);
  };

  const { isAdmin, isSuperAdmin, isNotAdmin, isEmployer, isRetailer, isEmployee } = useMemo(() => {
    const permissions = {
      isEmployer: false,
      isAdmin: false,
      isSuperAdmin: false,
      isNotAdmin: false,
      isRetailer: false,
      isEmployee: false,
    };

    if (!state.user) {
      return permissions;
    }

    if (state.user.roles.length === 1) {
      permissions.isAdmin = state.user.roles.includes(RoleEnum.SUPER_GLOBAL_ADMIN);
    }

    if (state.user.roles.length !== 1) {
      permissions.isSuperAdmin = state.user.roles.includes(RoleEnum.SUPER_GLOBAL_ADMIN);
    }

    permissions.isNotAdmin = !state.user.roles.includes(RoleEnum.SUPER_GLOBAL_ADMIN);
    permissions.isEmployer = state.user.roles.includes(RoleEnum.EMPLOYER_USER);
    permissions.isRetailer = state.user.roles.includes(RoleEnum.RETAILER);
    permissions.isEmployee = state.user.roles.includes(RoleEnum.EMPLOYEE_USER);

    return permissions;
  }, [state.user]);

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        signin,
        logout,
        signup,
        resetPassword,
        refreshToken,
        isEmployer,
        isEmployee,
        isRetailer,
        isAdmin,
        isSuperAdmin,
        isNotAdmin,
        setUpDeityMode,
        newPasswordSetup,
      }}
    >
      {initialization ? null : children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
