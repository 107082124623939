import { AxiosResponse } from 'axios';
import axiosServices from '../utils/axios';
import { QueryMethod } from './utils';
import type { BaseError, FetchParams, ValidationError } from './types';

export * from './types';

const API_URL = process.env.REACT_APP_API_URL || 'https://dev.gogetanursery.com';

export async function fetch<T>(
  path: string,
  method: QueryMethod,
  data: any = null,
  params?: FetchParams,
  configs?: any,
): Promise<T> {
  try {
    const response: AxiosResponse<T, ValidationError | BaseError> = await axiosServices({
      url: API_URL + path,
      method: method,
      data: data,
      params,
      ...configs,
    });

    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}
