/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, ReactNode } from 'react';
import uuid from 'react-uuid';
import { EntityResponse, ErrorResponse } from 'src/requests';
import { getTenantSettings, updateTenant } from '../requests/tenant';
import useAuth from 'src/guards/authGuard/UseAuth';
import { TenantSettingsDto } from '../requests/tenant/dto';
import { useMutation, useQuery } from '@tanstack/react-query';

interface PortalContextProps {
  children: ReactNode;
}

interface PortalSettingsCtx extends Partial<TenantSettingsDto> {
  actions: {
    isSuccess: boolean;
    isLoading: boolean;
    updateTenantSettings: (payload: UpdateTenantSettingsMutation) => void;
  };
  vatRate?: number;
  tenantSettings?: {
    currency: string;
  };
}

interface UpdateTenantSettingsMutation {
  showIntroduceDialogMenu?: boolean;
  file?: Blob;
}

const intitalCtxState: PortalSettingsCtx = {
  actions: {
    isSuccess: false,
    isLoading: false,
    updateTenantSettings: () => Promise.resolve(),
  },
};

const PortalContext = createContext<PortalSettingsCtx>(intitalCtxState);

const PortalProvider = ({ children }: PortalContextProps) => {
  const { isAuthenticated, isNotAdmin, isRetailer, isEmployee } = useAuth();

  const { data } = useQuery<EntityResponse<TenantSettingsDto>, ErrorResponse>({
    queryKey: ['tenant-settings'],
    queryFn: getTenantSettings,
    enabled: isAuthenticated && isNotAdmin && !isRetailer && !isEmployee,
  });

  const tenantSettingsMutation = useMutation({
    mutationFn: async (payload: UpdateTenantSettingsMutation) => {
      return await updateTenant({
        showIntroduceDialogMenu: Boolean(
          payload.showIntroduceDialogMenu ?? data?.showIntroduceDialogMenu,
        ),
      });
    },
  });

  const updateTenantSettings = (payload: UpdateTenantSettingsMutation) =>
    tenantSettingsMutation.mutate(payload);

  return (
    <PortalContext.Provider
      value={{
        ...(data ?? {}),
        actions: {
          isSuccess: tenantSettingsMutation.isSuccess,
          isLoading: tenantSettingsMutation.isLoading,
          updateTenantSettings,
        },
      }}
    >
      {children}
    </PortalContext.Provider>
  );
};

export { PortalContext, PortalProvider };

export const usePortalSettingsCtx = () => React.useContext(PortalContext);
