import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { toast } from 'sonner';
import { dispatch } from 'src/store/Store';
import { exportTableData, getExportTableDataStatus } from '../../requests/export-csv';
import { ExportTableDataRequest, ExportTableDataResponseDto } from '../../requests/export-csv/dto';

export type ExportTableDataItem = Partial<ExportTableDataResponseDto> & { isNew?: boolean };

interface State {
  jobs: ExportTableDataItem[];
}

const initialState: State = {
  jobs: [],
};

export const exportDataAsCsvSlice = createSlice({
  name: 'export-data-as-csv',
  initialState,
  reducers: {
    write: function (state, action: PayloadAction<{ jobId: string; isNew?: boolean }>) {
      state.jobs.push(action.payload);
    },
    update: function (state, action: PayloadAction<Partial<ExportTableDataResponseDto>>) {
      state.jobs = state.jobs.map((item) => {
        if (item.jobId === action.payload.jobId) {
          return { ...item, ...action.payload, isNew: false };
        }

        return { ...item };
      });
    },
    removeById: function (state, action: PayloadAction<string>) {
      state.jobs = state.jobs.filter(({ jobId }) => jobId !== action.payload);
    },
    reset: function (state) {
      state.jobs = [];
    },
  },
});

export const { write, update, removeById, reset } = exportDataAsCsvSlice.actions;

export const getStatus = createAsyncThunk(
  'export-data-as-csv/get-status',
  async (jobId: string, { dispatch }) => {
    dispatch(update({ jobId: String(jobId) }));

    return new Promise<ExportTableDataResponseDto>((resolve, reject) => {
      let exportResult = null;

      const interval = setInterval(async () => {
        try {
          exportResult = await getExportTableDataStatus({ id: jobId });

          dispatch(update(exportResult));

          if (!['WAITING', 'ACTIVE'].includes(exportResult.status)) {
            clearInterval(interval);
            resolve(exportResult);
          }
        } catch (e) {
          clearInterval(interval);
          reject(e);
        }
      }, 1500);
    });
  },
);

export const create = createAsyncThunk(
  'export-data-as-csv/create',
  async (payload: ExportTableDataRequest, { dispatch }) => {
    const { jobId } = await exportTableData(payload);

    // write the job id to the local storage to resume get status function after the application is reloaded
    let pendingArray: string[] = [];

    try {
      // get current ids from the local storage
      const pending = sessionStorage.getItem('export-data-as-csv');
      if (pending) {
        pendingArray = ((JSON.parse(pending) as unknown as number[]) ?? []).map(String);
      }
    } catch {}

    // store the job id to the local storage
    pendingArray.push(String(jobId));
    sessionStorage.setItem('export-data-as-csv', JSON.stringify(pendingArray));

    dispatch(write({ jobId: String(jobId), isNew: true }));

    // use timeout to prevent butch update
    setTimeout(() => {
      dispatch(getStatus(String(jobId)));
    });
  },
);

export const removeFromList = createAsyncThunk(
  'export-data-as-csv/remove-from-list',
  async (jobId: string, { dispatch }) => {
    // write the job id to the local storage to resume get status function after the application is reloaded
    let storedArray: string[] = [];

    try {
      // get current ids from the local storage
      const stored = sessionStorage.getItem('export-data-as-csv');
      if (stored) {
        storedArray = ((JSON.parse(stored) as unknown as number[]) ?? []).map(String);
      }
    } catch {}

    sessionStorage.setItem(
      'export-data-as-csv',
      JSON.stringify(storedArray.filter((s) => s !== jobId)),
    );

    dispatch(removeById(jobId));
  },
);

export const removeAll = createAsyncThunk(
  'export-data-as-csv/remove-all',
  async (_, { dispatch }) => {
    sessionStorage.removeItem('export-data-as-csv');

    dispatch(reset());
  },
);

export const init = createAsyncThunk('export-data-as-csv/init', async (_, { dispatch }) => {
  // get data from the local storage
  const pending = sessionStorage.getItem('export-data-as-csv');

  try {
    if (pending) {
      const pendingArray = JSON.parse(pending) as unknown as number[];

      // run get status function for each stored job id
      await Promise.all(
        pendingArray.map((jobId) => {
          dispatch(write({ jobId: String(jobId) }));
          dispatch(getStatus(String(jobId)));
        }),
      );
    }
  } catch {
    toast.error('Something went wrong. Please try again later.');
  }
});

setTimeout(() => {
  if (localStorage.getItem('token')) {
    dispatch(init());
  }
}, 1000);

export default exportDataAsCsvSlice.reducer;
