import { fetch } from '..';
import type { UserDto } from "../auth/dto";
import { QueryMethod } from '../utils';
import { UserDetailsDto } from './dto';
import type { CreateUserRequest } from "./dto";

export async function createUser(data: CreateUserRequest) {
  return await fetch<UserDto>(
    '/gogeta-api/v1/users',
    QueryMethod.POST,
    data,
  );
}

export function getUserDetails() {
  return fetch<UserDetailsDto>(
    '/gogeta-api/v1/user-details',
    QueryMethod.GET,
  );
}
