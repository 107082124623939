import React, { lazy } from 'react';
import Loadable from '../layouts/full/shared/loadable/Loadable';
import { Navigate } from 'react-router-dom';


/* ***Authentication**** */
const ViewWrapper = Loadable(lazy(() => import('./wrapper/ViewWrapper')));
const GiftMainPage = Loadable(lazy(() => import('../views/gift')));
const GetGiftCardPage = Loadable(lazy(() => import('../views/get-gift-card')));
const Error = Loadable(lazy(() => import('../views/authentication/Error')));

export const basicRoutes = [
    {
        path: '/',
        element: (
            <ViewWrapper title="Gift Cards">
                <GiftMainPage />
            </ViewWrapper>
        ),
    },
    {
        path: '/get-gift-cards',
        element: (
            <ViewWrapper title="Get Gift Cards">
                <GetGiftCardPage />
            </ViewWrapper>
        ),
    },
    { path: '*', element: <Navigate to={`/404`} /> },
    { path: `/404`, exact: true, element: <Error /> },
];
