import { FC } from 'react';
import { Toaster } from 'sonner';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { useRoutes } from 'react-router-dom';
import { useSelector } from 'src/store/Store';
import { UserProvider } from './context/user-context/user-context';
import { ThemeSettings } from './theme/Theme';
import RTL from './layouts/full/shared/customizer/RTL';
import ScrollToTop from './components/shared/ScrollToTop';
import { routes } from './routes';
import { AppState } from './store/Store';
import { PortalProvider } from './context/PortalContext';
import { Helmet } from 'react-helmet';
import { IS_PROD } from './utils/config';
import { ApplyProvider } from './context/apply-context';
import { ThemeProvider as ThemeProviderReact } from 'styled-components';

import 'rsuite/dist/rsuite.min.css';
import './theme/fonts.css';
import { defaultTheme } from './theme/new-theme';
import { CookieComponent } from './components/CookieBanner';

declare global {
  interface Window {
    dataLayer: object[];
  }
}

const App: FC = () => {
  const routing = useRoutes(routes);
  const theme = ThemeSettings();
  const isGTM = IS_PROD;

  const customizer = useSelector((state: AppState) => state.customizer);

  return (
    <ThemeProvider theme={theme}>
      <ThemeProviderReact theme={defaultTheme}>
        <ApplyProvider>
          <PortalProvider>
              <UserProvider>
                <RTL direction={customizer.activeDir}>
                  {isGTM && (
                    <>
                      <Helmet>
                        <script>{`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-WD7QQM8');`}</script>
                      </Helmet>
                    </>
                  )}
                  <CssBaseline />
                  <Toaster position="top-center" richColors closeButton />
                  <ScrollToTop>{routing}</ScrollToTop>
                  <CookieComponent />
                </RTL>
              </UserProvider>
          </PortalProvider>
        </ApplyProvider>
      </ThemeProviderReact>
    </ThemeProvider>
  );
};

export default App;
