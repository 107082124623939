import { fetch } from "../index";
import { QueryMethod } from "../utils";
import { ExportTableDataRequest, ExportTableDataResponseDto } from "./dto";


export async function exportTableData(payload: ExportTableDataRequest) {
  return await fetch<ExportTableDataResponseDto>(
    '/api/platform/v1/export-csv',
    QueryMethod.POST,
    payload,
  );
}

export async function getExportTableDataStatus(payload: { id: string }) {
  return await fetch<ExportTableDataResponseDto>(
    `/api/platform/v1/export-csv/status/${payload.id}`,
    QueryMethod.GET,
  );
}

export async function downloadPayrollInfo(id: string) {
  return await fetch<string>(
      `/api/platform/v1/export-csv`,
      QueryMethod.POST,
      {
        name: 'payrolls-info',
        id: [id],
      }
  );
}
