/* eslint-disable react-hooks/exhaustive-deps */
import { useQuery } from '@tanstack/react-query';
import React, { createContext, ReactNode } from 'react';
import { EntityResponse, ErrorResponse } from 'src/requests';
import useAuth from 'src/guards/authGuard/UseAuth';
import { getUserDetails } from 'src/requests/users';
import { UserDetailsDto } from 'src/requests/users/dto';
import { EmptyObj } from 'src/types/common';

interface ContextProviderProps {
  children: ReactNode;
}

export interface UserCtx {
  details: UserDetailsDto | EmptyObj;
}

const initialCtxState: UserCtx = {
  details: {},
};

const UserCtx = createContext<UserCtx>(initialCtxState);

export const useUserCtx = () => React.useContext(UserCtx);

export const UserProvider = ({ children }: ContextProviderProps) => {
  const { isAuthenticated, isEmployer, isRetailer, isEmployee } = useAuth();

  const { data } = useQuery<EntityResponse<UserDetailsDto>, ErrorResponse>({
    queryKey: ['details'],
    queryFn: getUserDetails,
    enabled: false,
  });

  return (
    <UserCtx.Provider
      value={{
        details: data ?? {},
      }}
    >
      {children}
    </UserCtx.Provider>
  );
};
