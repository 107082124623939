import { QueryKey } from '@tanstack/query-core/src/types';
import { isNil, isObject } from 'lodash';
import { BaseError, ErrorResponse, FetchParams, ValidationError } from './types';

export enum QueryMethod {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
  PATCH = 'PATCH',
  HEAD = 'HEAD',
  OPTIONS = 'OPTIONS',
}

const searchParamsMap: Record<string, string> = {
  page: 'page',
  pageSize: 'limit',
  sort: 'order',
  field: 'sort',
};

const isNotEmptyValue = (v: unknown) => !isNil(v) && v !== '';

export const mapQueryKeyToSearchParams = (
  queryKey?: QueryKey,
  nestedFields?: string[],
  nameOfNestedField?: string,
  additionalSortParams?: Record<string, string>,
): FetchParams => {
  const params: FetchParams = {};

  if (queryKey) {
    // @ts-ignore
    for (const [key, value] of queryKey) {
      // check values is not empty because 'key' can be string without 'value'
      if (!isNotEmptyValue(value)) {
        continue;
      }

      if (isObject(value)) {
        // @ts-ignore
        for (const [searchKey, searchValue] of Object.entries(value)) {
          if (isNotEmptyValue(searchValue)) {
            params[searchParamsMap[searchKey] ?? searchKey] = String(searchValue);
          }
          if (searchKey === 'sortBy' && isNotEmptyValue(searchValue) && additionalSortParams) {
            const sortValues = searchValue.split(':');
            if (additionalSortParams[sortValues[0]]) {
              params[searchKey] = `${additionalSortParams[sortValues[0]]}:${sortValues[1]}`
            }
          }
        }
      } else {
        if (isNotEmptyValue(value)) {
          params[key] = String(value);
        }
        params[key] = value;
      }
    }
  }

  return params;
};

export const transformToSearchParams = (params: FetchParams) => {
  const paramsResult = new URLSearchParams();
  for (const key in params) {
    if (params.hasOwnProperty(key)) {
      const value = params[key];
      paramsResult.append(key, `${value}`);
    }
  }

  return '?' + paramsResult.toString();
};

export const isAxiosBaseError = (error?: ErrorResponse): error is ErrorResponse<BaseError> =>
  !!(
    error &&
    error.isAxiosError &&
    !!error.response &&
    !!error.response.data &&
    'message' in error.response.data
  );

export const isAxiosValidationError = (
  error?: ErrorResponse,
): error is ErrorResponse<ValidationError> =>
  !!(
    error &&
    error.isAxiosError &&
    !!error.response &&
    !!error.response.data &&
    'errors' in error.response.data
  );
