export type RefreshTokenDto = {
  accessToken: string;
};

export interface JwtTokenDto {
  deityMode?: boolean;
  exp: number;
  iat: number;
}

export interface SetupNewPasswordDto {
  id: string;
  code: string;
  password: string;
  repeatedPassword: string;
}

export interface UserDto extends Partial<JwtTokenDto> {
  id: number;
  email: string | null;
  tenantId?: string;
  provider: string;
  socialId: string | null;
  firstName: string | null;
  lastName: string | null;
  photo?: FileDto | null;
  roles?: RoleEnum[] | null;
  status?: StatusDto;
  hash: string | null;
}

export interface AuthUser {
  id: number;
  email?: string;
  tenantId: number;
  roles: RoleEnum[];
}

export interface FileDto {
  id: string;
  path: string;
}

export interface StatusDto {
  id: number;
  name?: string;
}

export enum RoleEnum {
  SUPER_GLOBAL_ADMIN = 'SUPER_GLOBAL_ADMIN',
  EMPLOYER_USER = 'EMPLOYER_USER',
  RETAILER = 'retailer',
  EMPLOYEE_USER = 'EMPLOYEE_USER',
}

export enum ConvertedRoleEnum {
  EMPLOYER_USER = 'employer',
  SUPER_GLOBAL_ADMIN = 'super global admin',
  EMPLOYEE_USER = 'employee',
}

export interface AuthForgotPasswordDto {
  email: string;
}

export interface SendInvitationUserDto {
  email: string;
  tenantId: string;
}

export interface BaseSignUpByEmailRequest {
  email: string;
  firstName: string;
  lastName: string;
  jobTitle: string;
  phone: string;
}

export interface InviteUserRequest {
  userSignUp: BaseSignUpByEmailRequest;
  tenantId: string;
}
