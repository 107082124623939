import axios from 'axios';

const axiosServices = axios.create();

// interceptor for http
const AxiosInterceptor = ({ children }) => {
  // set jwt token to header
  axiosServices.interceptors.request.use(
    (config) => {
      return config;
    },
    async (error) => {
      return Promise.reject(error);
    },
  );

  // refresh token if 401 status
  axiosServices.interceptors.response.use(
    (res) => {
      return res;
    },
    async (err) => {
      return Promise.reject(err);
    },
  );

  return children;
};

export default axiosServices;
export { AxiosInterceptor };
