import { fetch } from '../index';
import { QueryMethod } from '../utils';
import {
  GlobalSettingsDto,
  RequestWelcomeSettingsDto,
  ResponseWelcomeSettingsDto,
  WorkplaceSettingsDto,
} from '../../views/account-settings/requestObjects/intex';
import {
  TenantSettingsDto,
  UpdateTenantRequest,
  TenantSettingsExtendedDto,
} from './dto';

export async function getTenantSettings() {
  const response = await fetch<TenantSettingsExtendedDto>(
    '/api/platform/v1/tenants/settings',
    QueryMethod.GET,
  );

  return response;
}

export async function updateGlobalTenantSettings(data: GlobalSettingsDto) {
  const response = await fetch<GlobalSettingsDto>(
    '/api/platform/v1/tenants/global-settings',
    QueryMethod.PUT,
    data,
  );

  return response;
}

export async function getGlobalSettings(): Promise<GlobalSettingsDto> {
  const response = await fetch<GlobalSettingsDto>(
    '/api/platform/v1/tenants/global-settings',
    QueryMethod.GET,
  );

  return response;
}

export async function getWelcomeSettings(): Promise<ResponseWelcomeSettingsDto> {
  const response = await fetch<ResponseWelcomeSettingsDto>(
    '/api/platform/v1/tenants/welcome-settings',
    QueryMethod.GET,
  );

  return response;
}

export async function updateWelcomeSettings(data: RequestWelcomeSettingsDto) {
  const response = await fetch<RequestWelcomeSettingsDto>(
    '/api/platform/v1/tenants/welcome-settings',
    QueryMethod.PUT,
    data,
  );

  return response;
}

export async function getWorkplaceSettings() {
  const response = await fetch<WorkplaceSettingsDto>(
    '/api/platform/v1/tenants/workplace-settings',
    QueryMethod.GET,
  );

  return response;
}

export async function updateWorkplaceSettings(data: WorkplaceSettingsDto) {
  const response = await fetch<WorkplaceSettingsDto>(
    '/api/platform/v1/tenants/workplace-settings',
    QueryMethod.PUT,
    data,
  );

  return response;
}
export async function updateTenant(body: UpdateTenantRequest): Promise<TenantSettingsDto> {
  return await fetch(`/gogeta-api/v1/tenant`, QueryMethod.PUT, body);
}

export async function downloadAgreementLink(key: string): Promise<string> {
  return await fetch<string>(`/api/platform/v1/file/download-pdf-files`, QueryMethod.POST, {
    key,
  });
}
