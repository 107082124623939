/* eslint-disable react-hooks/exhaustive-deps */

import React, {createContext, ReactNode, useState} from 'react';

import {basePortalURL} from "../../routes";
import {useNavigate} from "react-router";
import { ICalculatorData } from '../../helpers/nursery-calculations';
import { IEmployerData } from '../../components/pdf-form/types';

interface ContextProviderProps {
  children: ReactNode;
}

export interface ApplyCtx {
  setTenantData: (value: IEmployerData) => void;
  setDataCalculator: (value: ICalculatorData) => void;
  handleApply: () => void;
};

const initialCtxState: ApplyCtx = {
  setTenantData: (value: IEmployerData) => Promise.resolve(),
  setDataCalculator: (value: ICalculatorData) => Promise.resolve(),
  handleApply: () => Promise.resolve(),
};

const ApplyCtx = createContext<ApplyCtx>(initialCtxState);

export const useApplyCtx = () => React.useContext(ApplyCtx);

export const ApplyProvider = ({ children }: ContextProviderProps) => {
  const navigate = useNavigate();
  const [dataCalculator, setDataCalculator] = useState<ICalculatorData>();
  const [tenantDate, setTenantData] = useState<IEmployerData>();

  const handleApply = () => {
    if (!tenantDate) {
      return;
    }

    navigate(`/go/${tenantDate.accountNumber}/apply-now?params=${window.btoa(JSON.stringify({ ...dataCalculator }))}`);
  }

  return (
    <ApplyCtx.Provider
      value={{
        setTenantData,
        setDataCalculator,
        handleApply
      }}
    >
      {children}
    </ApplyCtx.Provider>
  );
};
